<template>
  <div class="home_warp">
    <!-- 内容  ,-->
    <titleNav title="社保小助手" :left-arrow="false" left-text="" />
    <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
      <van-swipe-item v-for="(item, index) in datas" :key="index"
        ><img :src="item.url" alt="" style="width: 100%"
      /></van-swipe-item>
    </van-swipe>

    <div class="prices">
      服务费<span style="font-weight: 700">￥{{ prices }}/年</span>
      <!-- 服务费<span style="font-weight: 700">￥240/年</span>   <van-icon name="service-o" /> -->
    </div>
    <div class="bank">
      <div>
        <p>政府许可</p>
        <p>指定机构</p>
      </div>
      <div>
        <p>在线服务</p>
        <p>实时反馈</p>
      </div>
      <div style="border-right: 0">
        <p>流程透明</p>
        <p>行业低价</p>
      </div>
    </div>
    <!-- <div class="fuwu">服务内容</div> -->
    <div class="xian"></div>
    <div class="bodys">
      <div
        style="
          font-size: 20px;
          font-weight: 700;
          line-height: 3vh;
          border-left: 5px solid #037fff;
          margin: 15px 0;
          padding-left: 5px;
        "
      >
        服务内容
      </div>
      <div style="font-size: 20px">为灵活就业人员代理社保（养老和医疗）</div>
      <div
        style="
          font-size: 20px;
          font-weight: 700;
          line-height: 3vh;
          border-left: 5px solid #037fff;
          margin: 15px 0;
          padding-left: 5px;
        "
      >
        服务对象
      </div>
      <div class="dw">
        <div>a</div>
        无雇工的个体工商户；
      </div>
      <div class="dw">
        <div>b</div>
        有工作单位但是没有缴纳社保的人员；
      </div>
      <div class="dw">
        <div>c</div>
        与用人单位解除或终止劳动关系的失业人员、辞职人员、自谋职业人员。
      </div>
      <div
        style="
          font-size: 20px;
          font-weight: 700;
          line-height: 3vh;
          border-left: 5px solid #037fff;
          margin: 15px 0;
          padding-left: 5px;
        "
      >
        办理流程
      </div>
      <div class="dw">
        <div>a</div>
        填写信息提交订单；
      </div>
      <div class="dw">
        <div>b</div>
        收到订单专职客服对接；
      </div>
      <div class="dw">
        <div>c</div>
        专职人员业务申报；
      </div>
      <div class="dw">
        <div>d</div>
        申报成功后专职客服及时反馈。
      </div>

      <div
        style="
          font-size: 20px;
          font-weight: 700;
          line-height: 3vh;
          border-left: 5px solid #037fff;
          margin: 15px 0;
          padding-left: 5px;
        "
      >
        社保缴费标准
      </div>
      <!-- <div class="dw">
        灵活就业养老保险：石家庄市2022年灵活就业人员基本养老保险缴费标准，可选择缴费基数：694.65元（60%）、1157.75元（100%）、2315.5元（200%）、3473.25元（300%）
      </div> -->
      <!-- 2023年标准 -->
      <div class="dw">
        灵活就业养老保险：石家庄市2023年灵活就业人员基本养老保险缴费标准，可选择缴费基数：745.33元（60%）、1242.22元（100%）、3726.65元（300%）
      </div>
      <!-- <div class="dw">
        灵活就业医疗保险：石家庄市2022年灵活就业人员医疗保险缴费标准5955.25*8%=476.42元
      </div> -->
      <!-- 2023年标准 -->
      <div class="dw">
        灵活就业医疗保险：石家庄市2023年灵活就业人员医疗保险缴费标准5955.25*8%=476.42元
      </div>
      <div
        style="
          font-size: 20px;
          font-weight: 700;
          line-height: 3vh;
          border-left: 5px solid #037fff;
          margin: 15px 0;
          padding-left: 5px;
        "
      >
        温馨提示
      </div>
      <div class="dw">
        <!-- <div>a</div> -->
        灵活就业人员，2022年缴纳养老保险费有困难的，可自愿暂缓缴费，2022年未缴费月度可于2023年底前进行补缴，缴费基数在2023年个人缴费基数上下限范围内自主选择，缴费年限累计计算。
      </div>
      <div
        style="
          font-size: 20px;
          font-weight: 700;
          line-height: 3vh;
          border-left: 5px solid #037fff;
          margin: 15px 0;
          padding-left: 5px;
        "
      >
        购买须知
      </div>
      <div class="dw">
        <div>1</div>
        服务费按年收取，从开户申请日期起，预交一年，中途中断概不退款，服务费到期后预收取下年度服务费。请到期前缴纳下年度服务费。
      </div>
      <div class="dw">
        <div>2</div>
        如需停止灵活就业人员代理社保（养老或医疗）业务，请提前20个工作日申请。
      </div>
      <div class="dw" style="padding-bottom: 100px">
        <div>3</div>
        因自身原因未及时办理相关中断手续的，由此产生的服务费用，全权自行负责。
      </div>
      <!-- <img src="@/assets/images/detile.png" alt="" style="width: 100%" /> -->
    </div>
    <!-- <floatBtn/> -->
    <div class="but">
      <div>
        咨询电话：<span @click="callPhone(Phone)">{{ Phone }}</span>
      </div>
      <button @click="handle">我要办理</button>
      <button @click="handles" style="background-color: #1aa034">
        我的订单
      </button>
    </div>
    <!-- 悬浮客服 -->
    <div class="content">
      <div
        :style="{ transform: 'translate3d(' + xMove + 'px,' + yMove + 'px,0)' }"
        class="touch"
        @touchstart="handleStart"
        @touchmove="handleMove"
        @touchend="handleEnd"
      >
        <div class="div" @click="service">
          <img src="../../assets/images/lanxi.png" alt="" />
          联系客服
        </div>
        <!-- <button class="buttons" open-type="contact" session-from="">
          
          联系客服
        </button> -->
      </div>
    </div>
  </div>
</template>

<script>
var curPoint = {
  x: 0,
  y: 0,
}; // 记录原点
var startPoint = {};
// 标志位（只触发点击事件按，并没有移动-就不必触发end事件）
var isTouchMove = false;
import titleNav from "@/components/conetnts/titleNav.vue";
// import floatBtn from "@/components/floatBtn.vue";
import { Button, Dialog, Icon, Toast, NavBar, Swipe, SwipeItem } from "vant";
import { banner, price, indexs } from "@/network/index.js";
import login from "../../assets/js/code.js";
import axios from "axios";
import code from "../../assets/js/code.js";
export default {
  components: {
    titleNav,
    // floatBtn,
	[Toast.name]: Toast,
    [Button.name]: Button,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [NavBar.name]: NavBar,
    [Icon.name]: Icon,
    [Dialog.Component.name]: Dialog.Component,
  },
  data() {
    return {
      xMove: 0,
      yMove: 0,
      datas: [],
      prices: "",
      id: "",
      Phone: "400-8877-827",
    };
  },
  created() {
    window.scrollTo(0, 0);
    this.codeSplicet();
    //this.guanbi();
  },
  mounted() {
    this.login();
    this.banners();
    // this.time = setTimeout(this.timeid, 7200000);
    this.panduan();
  },

  methods: {
    panduan() {
      // let code = sessionStorage.getItem("id");
      // console.log("code", code);
      // if (code == 224 || code == "224") {
        if (/MicroMessenger/.test(window.navigator.userAgent)) {
          this.codeSplicet();
        }
      // }
    },
    // 点击客服
    service() {
      // let url = "https://work.weixin.qq.com/kfid/kfc2de3dc57f9907732";
      let url = "http://www.sjzydrj.net/index.php/Home/News/index/id/93.html";

      // window.localtion.href = url
      window.location.replace(
        // "https://work.weixin.qq.com/kfid/kfc2de3dc57f9907732"
        "http://www.sjzydrj.net/index.php/Home/News/index/id/93.html"
      );
    },
    // 悬浮客服
    handleStart(ev) {
      // console.log('start',ev);
      // 记录一开始手指按下的坐标
      var touch = ev.changedTouches[0];
      startPoint.x = touch.pageX;
      startPoint.y = touch.pageY;
    },
    handleMove(ev) {
      // console.log('move',ev);
      // 防止页面高度很大，出现滚动条，不能移动-默认拖动滚动条事件
      ev.preventDefault();

      isTouchMove = true;

      var touch = ev.changedTouches[0];
      var diffPonit = {}; // 存放差值
      var movePonit = {
        // 记录移动的距离
        x: 0,
        y: 0,
      };
      diffPonit.x = touch.pageX - startPoint.x;
      diffPonit.y = touch.pageY - startPoint.y;
      // 移动的距离 = 差值 + 当前坐标点
      movePonit.x = diffPonit.x + curPoint.x;
      movePonit.y = diffPonit.y + curPoint.y;
      this.move(movePonit.x, movePonit.y);
    },
    handleEnd(ev) {
      // console.log('end', ev);
      if (!isTouchMove) return;

      //  更新坐标原点
      var touch = ev.changedTouches[0];

      curPoint.x += touch.pageX - startPoint.x;
      curPoint.y += touch.pageY - startPoint.y;

      // 重置
      isTouchMove = false;
    },
    move(x, y) {
      x = x || 0; // 没有传就是0
      y = y || 0;
      this.xMove = x;
      this.yMove = y;
      // translate3d  (tx,ty,tz)  在X轴偏移tx，在Y轴偏移ty，在Z轴偏移tz，单位px
    },
    callPhone(phoneNumber) {
      window.location.href = "tel://" + phoneNumber;
    },
    guanbi() {
      let ids = sessionStorage.getItem("id");
      let url = window.location.href;
      if (url == "http://lhjy.sjzydrj.net/") {
        setTimeout(function () {
          //这个可以关闭安卓系统的手机
          document.addEventListener(
            "WeixinJSBridgeReady",
            function () {
              WeixinJSBridge.call("closeWindow");
            },
            false
          );
          //这个可以关闭ios系统的手机
          WeixinJSBridge.call("closeWindow");
        });
      }
    },
    banners() {
      // 轮播图
      let _this = this;
      let data = {
       // type: 7,
       miniprogramid: 7,
      };
      banner(data).then((res) => {
        this.datas = res.data.data;
      });
      let datas = {
        userid: sessionStorage.getItem("id"),
        // userid: "3646690",
      };
      price(datas).then((ress) => {
        _this.prices = ress.data.data;
      });
    },
    // 获取code
    codeSplicet() {
      // let codes = sessionStorage.getItem("id");
      // console.log("codes", codes);
      // if (codes) {
      //   this.login();
      // }
      // subscribe 1是关注 0 未关注
      // openid nickname
      // let codes = sessionStorage.getItem("id");
      let codes = sessionStorage.getItem("id");
	  console.log("===codes===", codes)
      if (
        codes == undefined ||
        codes == null ||
        codes == "undefined"
         // codes == 224 ||
         // codes == "224"
      ) {
        // window.location.replace( 
        //   `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx27607c3940366258&redirect_uri=http://health.sjzydrj.net/home&response_type=code&scope=snsapi_base&state=1#wechat_redirect`
        //   //`https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx2ea8244470e4aaf1&redirect_uri=http://lhjy.sjzydrj.net/home&response_type=code&scope=snsapi_base&state=1#wechat_redirect`
        //   // `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx2ea8244470e4aaf1&redirect_uri=http://lhjy.sjzydrj.net?type=7&response_type=code&scope=snsapi_userinfo&state=1#wechat_redirect`
        // );
        let url = window.location.href;
        // console.log(url, 123);
        // sessionStorage.setItem("id", 333);
        this.login();
        // const codeGet = login.getCode();
        // sessionStorage.setItem("id", 222);
      }
      // else {
      //   console.log(3);
      //   this.login();
      // }
    },
    timeid() {
      // sessionStorage.setItem("id", "undefined");
      // localStorage.setItem("userAll", "undefined");
    },

    // login
    login() {
      var _this = this;
      let url = window.location.href;
      sessionStorage.setItem("urls", url);
      let codes = this.$route.query.code;
	  console.log("codes的值========================：", codes);
      let codee = sessionStorage.getItem("userAll");
		//Toast(codes);
      let data = {
        //code: '081rtb100WmQAN1OD9100x9cBP1rtb1U',
        code: codes,
        type: 7,
      };
	  this.codes = codes
      indexs(data).then((res) => {
		 // Toast(JSON.stringify(res));
        let id = res.data.data.id;
        if (id !== undefined) {
          _this.id = id;
          sessionStorage.setItem("id", id);
          // 费用
          let datas = {
            userid: sessionStorage.getItem("id"),
            // userid: "3646690",
          };
          price(datas).then((ress) => {
            _this.prices = ress.data.data;
          });
        } else {
          let ids = sessionStorage.getItem("id");
          sessionStorage.setItem("id", ids);
          let datas = {
            userid: sessionStorage.getItem("id"),
            // userid: "3646690",
          };
          price(datas).then((ress) => {
            _this.prices = ress.data.data;
          });
        }
      });
    },
    // 点击办理按钮
    handle() {
      this.$router.push("/information");
    },
    // 点击订单按钮
    handles() {
      this.$router.push("/order");
    },
  },
  beforeDestroy() {
    clearTimeout(this.timer);
  },
};
</script>

<style scoped lang="scss">
// 悬浮客服
.content {
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100vw;
  // height: 100vh;
}
.touch {
  position: fixed;
  right: 20px;
  bottom: 45vh;
  width: 60px;
  height: 60px;
  /* 知识点
	        line-height是行高，针对的对象是文字，height针对的是容器，
	        也就是高度，当height和line-height值相同时会居中，
	        当line-height值小于height时文字向上移动，反之向下移动。
	         */
  // line-height: 45px; /* 文字垂直居中 */
  text-align: center; /* 水平居中 */
  border-radius: 50%;
  color: #fff;
  /* 去除标签点击事件高亮效果 */
  -webkit-tap-highlight-color: transparent;
  /* 使用transform: translate3d 处理性能高 GUP */
  .div {
    width: 100%;
    height: 100%;
    color: #000000;
    font-size: 14px;
    background-color: #ffffff;
    border-radius: 5px;
    border: 1px solid #c5c5c5;
    img {
      width: 25px;
      margin-left: 17.5px;
      padding-top: 8px;
      display: block;
    }
  }
}
.home_warp {
  height: 100%;
  background: #ffffff;
  background-color: #ffffff;
  .my-swipe .van-swipe-item {
    color: #fff;
    font-size: 20px;
    line-height: 150px;
    text-align: center;
    background-color: #000000;
    height: 19.5vh;
  }
  .prices {
    background-color: #037fff;
    width: 130px;
    padding: 5px;
    text-align: center;
    border-radius: 13px;
    position: relative;
    left: 10px;
    top: -40px;
    font-size: 15px;
  }
  .bank {
    margin-top: -55px;
    width: 100%;
    height: 80px;
    background-color: #000000;
    color: #ffffff;
    font-size: 18px;
    padding-top: 24px;
    div {
      margin-top: 15px;
      text-align: center;
      width: 32.8%;
      display: inline-block;
      border-right: 2px #ffffff solid;
    }
  }
  .fuwu {
    font-size: 2.5vh;
    margin: 10px 20px;
    padding-left: 5px;
    border-left: 5px solid #037fff;
  }
  .xian {
    height: 1px;
    width: 100%;
    background-color: #e7e7e7;
  }
  .bodys {
    margin: 0px 30px;
    font-size: 2.3vh;
    margin-bottom: 50px;
  }
  .dw {
    margin-top: 5px;
    margin-bottom: 5px;
    font-size: 20px;
    div:nth-child(1) {
      display: inline-block;
      background-color: #9fc7f8;
      border-radius: 50%;
      width: 20px;
      height: 20px;
      line-height: 20px;
      text-align: center;
      margin-right: 5px;
      color: #ffffff;
    }
  }
  .bot {
    font-size: 2vh;
    margin: 20px;

    .bots {
      display: flex;
      margin-top: 10px;
      .dian {
        width: 15px;
        height: 15px;
        background: #86cbff;
        display: inline-block;
        border-radius: 50%;
        margin: 6px;
      }
      .zi {
        width: 100%;
        // text-overflow: ellipsis;
        // word-wrap: break-word;
        color: #797979;
        padding-bottom: 15px;
      }
    }
  }
  .but {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 85px;
    background-color: #ffffff;
    font-size: 2.5vh;
    div {
      text-align: center;
      line-height: 40px;
    }
    button {
      background-color: #0f81fd;
      color: #ffffff;
      border: 0;
      border-radius: 5px;
      width: 42.5vw;
      margin-left: 5vw;
      text-align: center;
      height: 40px;
    }
  }
}
</style>
